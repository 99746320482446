
















import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import api from '@/api'

@Component({
    name: "agentEquity",
    components: {}
})

export default class extends Vue {
    value = false

    activated() {
        this.query()
    }

    query() {
        api
            .post('J27315')
            .then(res => {
                this.value = res.audit_register == 1 ? true : false
            })
    }

    change(e: any) {
        api
            .post('J27314', {audit_register: e ? '1' : '2'})
            .then(res => {
                this.$message.success('操作成功')
            })
    }
}
